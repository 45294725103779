<template>
  <div>
    <Section1 :keyWord="'La mejor solución de financiación si está inscrito en ASNEF'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Préstamos inmediatos con ASNEF: Supera imprevistos con rapidez con Ibancar </strong></h2>
      <p>Los <strong>préstamos con ASNEF</strong> de Ibancar llegan al rescate para ayudarte a superar esos momentos difíciles. Te ayudamos con préstamos personales rápidos y seguros con tu coche como garantía. Nuestros préstamos personales son la forma más sencilla de solucionar tus necesidades financieras en el mismo día y pueden ser cancelados cuando tu quieras</p>
      <p>Si tienes un coche, puedes usarlo como garantía sin dejar de conducirlo y sin cambiar la titularidad. Tu coche funciona como un aval para conseguir un préstamo en menos de 24 horas y solucionar tus deudas.</p>
      <p>El préstamo de Ibancar como cualquier otro préstamo tiene una cuota fija que amortiza capital e intereses y está pensado para que consigas financiación rápida y luego tengas 36 meses de cuota o si tienes posibilidad poder cancelarlo cuando quieras.</p>
   
      <h3 class=""><strong>¿Necesitas liquidez de forma urgente para resolver un problema inesperado? <br> Rellena el formulario y te respondemos en minutos <a href="/">aquí</a></strong></h3>
      
      <h2 class="naranja mt-5"><strong>¿Cómo te podemos ayudar si estás en ASNEF?</strong></h2>
      <p>En el mundo de las finanzas, surgen imprevistos que exigen soluciones urgentes. Si te encuentras en la lista ASNEF, acceder a financiación tradicional puede ser un obstáculo.</p>
      <p>¡Pero no te preocupes!</p>
      <p>Estar en ASNEF no tiene que limitar tus posibilidades. Los <strong>créditos con ASNEF de Ibancar</strong> son una alternativa rápida y accesible para solventar tus necesidades financieras de forma ágil. Están diseñados para ofrecer respuestas inmediatas a personas que se encuentran en situaciones urgentes, eliminando las largas esperas y la burocracia tradicional para, al final, recibir una negativa en su solicitud de préstamo por parte de los bancos.</p>

      <h2 class="naranja"><strong>Obtén un préstamo personal, mantén la titularidad y sigue conduciendo</strong></h2>
      <p>Imagina obtener un préstamo rápido y fácil utilizando tu coche como aval, sin tener que dejarlo en un depósito ni pagar cuotas adicionales para seguir usándolo. En Ibancar, hacemos las cosas diferentes. Aquí podrás seguir conduciéndolo sin restricciones aún estando en el listado de ASNEF. No te pediremos que entregues el vehículo, ni una copia de las llaves. ¡Así de sencillo!</p>
      
      <h2 class="naranja"><strong>Ventajas de los préstamos con ASNEF en Ibancar</strong></h2>
      <ul>
        <li><strong>Rapidez en la aprobación</strong>: Olvídate de largos procesos. Envía la solicitud a través del <a href="/">formulario</a> y te responderemos en cuestión de minutos.</li>
        <li><strong>Recibe el dinero en el mismo día</strong>: ¿Necesitas el dinero hoy? En Ibancar podemos hacer que recibas el dinero en cuenta en el mismo día.</li>
        <li><strong>Amortiza desde el primer día y cancela cuando quieras</strong>: Si tienes una urgencia esporádica no pasa nada, puedes anular el contrato en cuanto quierás.</li>
        <li><strong>Accesibilidad</strong>: A pesar de estar en ASNEF, esta opción de financiación se enfoca en otros aspectos de tu perfil financiero, aumentando las posibilidades de obtener el crédito que necesitas.</li>
        <li><strong>Flexibilidad</strong>: Paga en cómodas cuotas que se adapten a tu disponibilidad.</li>
        <li><strong>Atención personalizada</strong>: Siempre tendrás un agente disponible para cambiar la fecha de pago, amortizaciones parcial o total, tener disponibles meses de carencias y otras facilidades para que los pagos de las cuotas sean lo más cómodo posible.</li>
      </ul>

      <h2 class="naranja"><strong>Requisitos para recibir un préstamo con Ibancar</strong></h2>
      <p>El proceso es sencillo y rápido, en solo 4 pasos puedes tener el dinero que necesitas. Para poder obtener un préstamo con tu coche de manera online en cuestión de minutos, solo necesitas rellenar la solicitud a través del <a href="/">formulario</a> de contacto en nuestra web.</p>

      <p>Recibirás una propuesta de crédito personalizada y luego deberás presentar la siguiente documentación personal:</p>
      <ul>
        <li>Fotos de tu DNI, ambas caras en fondo blanco.</li>
        <li>Última nómina, pensión o autónomo.</li>
        <li>Foto Selfie con DNI.</li>
      </ul>

      <p>Además, también necesitaremos que nos envíes la siguiente documentación del vehículo:</p>
      <ul>
          <li>Permiso de circulación y ficha técnica.</li>
          <li>1 Foto del cuentakilómetros con DNI.</li>
          <li>4 fotos del coche y 1 video del coche.</li>
      </ul>

      <p>¡Supera obstáculos financieros sin esperas! Solicita tu préstamo inmediato sin importar ASNEF y recupera el control de tu economía.</p>

     
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamos inmediatos con ASNEF: Supera imprevistos con rapidez con Ibancar</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <router-link :to="'/prestamos-con-asnef-y-nomina-rapidos'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con ASNEF y nómina</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Te han rechazado un préstamo por estar incluido en el fichero de ASNEF? Descubre la solución que te da Ibancar.</p>
              </div>
            </router-link>
          </div>
          <div class="card m-4">
            <router-link :to="'/prestamos-asnef-en-el-acto'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con ASNEF en el acto</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Estás en ASNEF y necesitas un préstamo hoy mismo? Recibe un préstamo en el acto.</p>
              </div>
            </router-link>
          </div>
           <div class="card m-4">
            <router-link :to="'/prestamos-asnef-sin-intermediarios'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo con ASNEF sin intermediarios</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Préstamo con ASNEF sin intermediarios. Descubre el préstamo con ASNEF sin intermediarios. Recibe el dinero hoy.</p>
              </div>
            </router-link>
          </div>
      </div>
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4">
            <router-link :to="'/prestamos-particulares-asnef'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamso para particulares en ASNEF</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Préstamos para particulares en ASNEF. Si eres un particular y estás en ASNEF, con Ibancar puedes recibir dinero hoy mismo.</p>
              </div>
            </router-link>
          </div>
           <div class="card m-4">
            <router-link :to="'/prestamo-1000-euros-asnef'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo 1000€ ASNEF</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Préstamo 1000€ ASNEF. Si estás en ASNEF y necesitas 1000€, Ibancar tiene la solución para ti.</p>
              </div>
            </router-link>
          </div>
           <div class="card m-4">
            <router-link :to="'/prestamos-urgentes-asnef'" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos urgentes con ASNEF</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Préstamos urgentes con ASNEF,¿Necesitas un crédito urgente estando en ASNEF? Descubre Ibancar.</p>
              </div>
            </router-link>
          </div>
      </div>
      
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCoche',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamo con ASNEF',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Necesitas financiación? Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.'},
        {name:'keywords', content:'prestamo asnef'}
      ],
      link:[
        {href: 'https://ibancar.com/empenar-coche', rel:'alternate', hreflang:'es-es'},
        {href: 'https://ibancar.mx/empeno-de-autos-en-ibancar', rel:'alternate', hreflang:'es-MX'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 100%;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>