<template>
  <div>
    <Section1 :keyWord="'Préstamos urgentes con ASNEF'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Préstamos urgentes con asnef</strong></h2>
      <p>¿Necesitas dinero urgente pero no te lo conceden por estar incluido en ficheros de impagados? No te preocupes.</p>
      <p>Lo primero que tienes que saber es que existen <strong> préstamos urgentes con asnef</strong> que te permiten conseguir liquidez en tu cuenta sin tener en cuenta este tipo de listados.</p>
      <p>Pero ojo, no todos los créditos de este tipo son iguales ni todas las compañías te garantizan recibir el dinero con la urgencia que necesitas.</p>
      <p>A continuación te explicamos el paso a paso para que puedas disponer de un <strong> préstamos urgentes con asnef</strong>  completando la solicitud de manera 100% online y recibiendo el dinero en tu cuenta en cuestión de horas.</p>


      <h3 class="naranja"><strong>Cómo conseguir préstamos urgentes con ASNEF</strong></h3>
      <p>Estos son los pasos a seguir para conseguir <strong> préstamos urgentes con asnef</strong> :</p>
      <ul>
        <li>Entra en la web de Ibancar</li>
        <li>Rellena el formulario introduciendo la cantidad que necesitas</li>
        <li>Recibirás una propuesta de crédito preconcedida</li>
        <li>Acepta la propuesta y aporta la documentación requerida</li>
        <li>Nuestros agentes validan la documentación y ejecutamos la transferencia</li>
      </ul>
      <p>¿Has visto lo sencillo que es acceder a préstamos urgentes con asnef a través de Ibancar? Quizá te estés preguntando qué pasa con el fichero de impagados.</p>
      <p>En Ibancar sabemos que cualquier persona puede aparecer en este tipo de listados.</p>
      <p>La confianza en nuestros clientes se basa en su aportación de la documentación que acredita que cuentan con una fuente de ingresos que le permita hacer frente a los pagos. Así de simple.</p>


      <h3 class="naranja"><strong>Préstamos online en el acto con Asnef</strong></h3>
      <p>Esta es una de las grandes ventajas de los <strong> préstamos urgentes con Asnef</strong> en Ibancar. El proceso se completa de manera 100% online.</p>
      <p>Los trámites son inmediatos, y el tiempo que transcurre entre la solicitud y la disponibilidad de liquidez en la cuenta depende de la agilidad del cliente al aportar la documentación.</p>

      <h3 class="naranja"><strong>Préstamos con Asnef fiables</strong></h3>
      <p>Cuando te planteas solicitar préstamos urgentes con Asnef es imprescindible que confíes en una compañía seria que cuente con experiencia en el sector y una cartera de clientes satisfecha con sus servicios.</p>
      <p>Estas son algunas de las características diferenciales que definen a Ibancar como referente en préstamos urgentes con Asnef:</p>
      <ul>
        <li>Desde el momento que contactas con Ibancar se te asigna un gestor personal para ayudarte durante todo el proceso.</li>
        <li>Los clientes de Ibancar cuentan con un área personal en la web desde la que pueden consultar todos los datos de su préstamo y realizar solicitudes.</li>
        <li>Los créditos con aval de coche de Ibancar permiten a los solicitantes seguir disfrutando de su vehículo sin ningún tipo de restricción</li>
        <li>En Ibancar disponemos de un sistema de pagos muy flexible. Los clientes pueden escoger entre diferentes formas de pago, cuentan con días de cortesía para abonar las cuotas, eligen la fecha del mes en la que se ejecutan los pagos, pueden solicitar periodos de carencia y hasta disponen de un servicio de compra de coche en caso de que no puedan hacer frente al pago.</li>
        <li>Los clientes de Ibancar tienen acceso a un paquete de servicios exclusivo que incluye revisión técnica anual de mantenimiento gratuita.</li>
      </ul>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamos-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo con ASNEF</h5>
                <span class="caret-sign">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                  </svg>
                </span>
              <p class="card-text pl-1 pt-2">Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamosAsnefRapidos',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamos urgentes con asnef',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Estas son las opciones que te interesan si estás buscando un préstamo rápido y te encuentras en ASNEF.'},
        {name:'keywords', content:'préstamos urgentes con asnef'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>